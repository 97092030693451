import ErrorPage from "next/error";
import Head from "next/head";
import { useRouter } from "next/router";
import React, { ReactElement, useEffect } from "react";
import Footnote from "src/components/atoms/Footnote";
import { BlockRenderer } from "src/components/molecules/BlockRenderer";
import LeadFormSection from "src/components/forms/LeadForm";
import Faq from "src/components/organisms/Faq";
import RichContent from "src/components/organisms/RichContent";
import TwoStackColumn from "src/components/organisms/TwoStackColumn";
import { PageSeo } from "src/components/SEO";
import siteMetadata from "src/data/siteMetadata";
import { IPageLandingPageFields } from "src/types/generated/contentful";
import MainHeader from "src/components/organisms/MainHeader";

declare global {
  interface Window {
    dataLayer: any[] | undefined;
    blueshift: any;
    blueshiftProductId: string | undefined;
  }
}

const ContentTypeToComponentMap = {
  richContent: RichContent,
  twoStackColumn: TwoStackColumn,
  faq: Faq,
};

export type GenericPagePageProps = {
  page: IPageLandingPageFields;
};

// TODO: add back the generic page props remove any
const GenericPage = (data: any): ReactElement => {
  const { page, settings, header, footerMenu } = data;
  const router = useRouter();

  const slug = page?.fields?.slug;
  if (!slug) {
    console.error("ERROR: no slug found", JSON.stringify(page));
  }
  const seoTitle = page?.fields?.seo?.fields?.title ?? page?.fields?.title;
  const seoDescription = page?.fields?.seo?.fields?.description;
  const seoImages = page?.fields?.seo?.fields?.images;
  const noIndex = page?.fields?.seo?.fields?.no_index || false;
  const noFollow = page?.fields?.seo?.fields?.no_follow || false;
  const customFooter = page?.fields?.content?.fields?.customFooter;

  const blueshiftProductId: string =
    page?.fields?.productType?.fields?.productId;

  useEffect(() => {
    if (typeof blueshiftProductId !== "undefined") {
      window.dataLayer = window.dataLayer || [];

      if (window.blueshift) {
        window.dataLayer.push({
          event: "blueshiftPageView",
          blueshiftProductId,
        });
        console.log("Fired bsPageView:", blueshiftProductId);
      } else {
        window.dataLayer.push({
          blueshiftProductId,
        });
        console.log("Fired GA Only Err no BS:", blueshiftProductId);
      }
    }
  }, [blueshiftProductId]);

  if (!router.isFallback && !page) {
    return <ErrorPage statusCode={404} />;
  }

  if (router.isFallback) {
    return <div>Loading…</div>;
  }

  // console.log(
  //   "customContactForm",
  //   JSON.stringify(page?.fields?.content?.fields?.customContactForm?.fields)
  // );

  return (
    <>
      <Head>
        <title>{seoTitle}</title>
      </Head>
      <PageSeo
        url={`${siteMetadata.mainSiteUrl}/landing-page/${slug}`}
        title={seoTitle}
        description={seoDescription}
        images={seoImages}
        nofollow={noFollow}
        noindex={noIndex}
        datePublished={new Date(page?.sys?.createdAt).toISOString()}
        dateModified={new Date(page?.sys?.updatedAt).toISOString()}
        authorName={page?.fields?.seo?.fields?.author}
        authorPicture={page?.fields?.seo?.fields?.authorPicture}
      />

      <MainHeader
        id="header"
        data={{ ...header, ...settings, layout: "logo no-nav show-phone" }}
      />
      <article>
        {/*<PostHeader post={page} />*/}
        <div>
          <BlockRenderer
            block={page?.fields?.content?.fields?.content}
            layoutId={page["data-layoutid"]}
          />
        </div>
      </article>
      <footer>
        {customFooter ? (
          <BlockRenderer block={customFooter} layoutId={"customFooter"} />
        ) : (
          <BlockRenderer layoutId="footer" block={footerMenu} />
        )}
      </footer>
    </>
  );
};

export default React.memo(GenericPage);
