import { QueryClient } from "@tanstack/react-query";
import { GetStaticPaths, GetStaticProps } from "next";
import { getPage, getPagesOfType } from "src/lib/api";
import { isPreviewEnabled } from "src/lib/preview";
import getFooterSection from "src/services/contentful/footer/getFooterMenu";
import { getHeaderMenuGraphQL } from "src/services/contentful/mainHeader/getMainHeader";
import { getProductTypes } from "src/services/contentful/productType/productTypeToBlueshift";
import { getSiteSettingsGql } from "src/services/contentful/settings/settings";

export { default } from "src/pages/landing";

// export const getStaticProps: GetStaticProps = async ({
//   params,
//   preview = false,
// }) => {
//   const data = await getPages({ slug: 'annual-review', preview })

//   return {
//     props: {
//       preview,
//       data,
//       headerVisible: false,
//     },
//   }
// }

export const getStaticProps: GetStaticProps = async (context) => {
  const slug = context?.params?.slug;
  const preview = isPreviewEnabled(context);
  const queryClient = new QueryClient();
  const page = await getPage({
    slug,
    preview,
    pageContentType: "pageLandingPage",
  });

    // set defaults for no_index and no_follow if they are not set
    const seoFields = page?.fields?.seo?.fields as any;
    if (!seoFields?.no_index) {
      seoFields.no_index = true;
    }
    if (!seoFields?.no_follow) {
      seoFields.no_follow = true;
    }
    // end set

  let headergql = await getHeaderMenuGraphQL({
    preview: preview || false,
  });

  let settings = await getSiteSettingsGql({
    queryClient,
    preview: preview || false,
  });

  let products = await getProductTypes({
    preview: preview || false,
  });

  let footerMenu = await getFooterSection({
    query: {
      limit: 1,
      include: 5,
      select: "sys.id,sys.contentType,sys.type,fields",
    },
    preview: preview || false,
  });

  return {
    props: {
      products,
      footerMenu,
      page,
      preview,
      headerVisible: false,
      header: headergql,
      settings,
    },
  };
};

export const getStaticPaths: GetStaticPaths = async (data) => {
  //console.log("genericPages:[slug]", data);
  //console.log("CONTENTFUL_ENVIRONMENT", process.env.CONTENTFUL_ENVIRONMENT);
  // const entries: EntryCollection<Pick<IPageFields, "slug">> = await getClient(
  //   false
  // ).getEntries({
  //   content_type: "pageGenericPage",
  //   //select: "slug",
  // });

  const entries = await getPagesOfType({
    pageContentType: "pageLandingPage",
  });

  //console.log("entries: ", entries);

  // entries
  //   .filter(({ sys }) => sys?.contentType?.sys?.id === "pageGenericPage")
  //   .map(({ fields }) =>
  //     console.log("[slug]:entries", JSON.stringify(sys?.contentType?.sys?.id))
  //   );

  // entries
  //   .filter(
  //     ({ fields }) =>
  //       fields?.content?.sys?.contentType?.sys?.id === "pageGenericPage"
  //   )
  //   .map(({ fields }) =>
  //     console.log("[slug]:entries", fields?.content?.sys?.contentType?.sys?.id)
  //   );

  const paths = entries
    .filter(
      ({ fields }: any) =>
        fields?.content?.sys?.contentType?.sys?.id === "pageLandingPage"
    )
    .map(({ fields }: any) => `/landing-pages/${fields?.slug.toLowerCase()}`);

  // console.log("paths: ", paths);

  return {
    paths,
    fallback: true,
  };
};
